html, body {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.center {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1,h2 {
  color: #707070;
  margin-top: 0;
  background-color: transparent;
}

.back {
  z-index: 0;
//opacity: 0.075;
  opacity: 0.3;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
//width: 100%;
  max-height: 350px;
  min-height: 200px;
  max-width: 450px;
  margin: auto;
}
