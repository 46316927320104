html, body {
  height: 100%;
  margin: 0;
}

.center {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  padding-left: 40px;
}

h1,h2 {
  color: #707070;
  margin-top: 0;
  background-color: transparent;
}

h1 {
  margin: 0 0 10px 0;
}

.back {
  z-index: 0;
//opacity: 0.075;
  opacity: 0.3;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
//width: 100%;
  max-height: 350px;
  min-height: 200px;
  max-width: 450px;
  margin: auto;
}